<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Поиск заказа, введите номер"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action">
        <div class="action-left">
          <b-form-checkbox
            :checked="selectAllEmailCheckbox"
            :indeterminate="isSelectAllEmailCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            Выбрать все
          </b-form-checkbox>
        </div>
        <div
          v-if="false && selectedEmails.length"
          class="align-items-center"
          :class="{'d-flex': selectedEmails.length}"
        >

          <b-dropdown
            v-if="selectedEmails && selectedEmails.length"
            variant="link"
            no-caret
            toggle-class="p-0"
            class="ml-75"
            right
          >
            <template #button-content>
              <feather-icon
                icon="DownloadIcon"
                size="17"
                class="align-middle text-body"
              />
              Скачать
            </template>
            <b-dropdown-item v-if="false" @click="$emit('update-email-label', 'personal')">
              <span class="mr-50 bullet bullet-success bullet-sm" />
              <span>HTML</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('update-email-label', 'company')">
              <span class="mr-50 bullet bullet-primary bullet-sm" />
              <span>XML</span>
            </b-dropdown-item>
          </b-dropdown>

        </div>
        <div v-if="selectedEmails.length">нет действий</div>
      </div>

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="email in emails"
            :key="email.id"
            tag="li"
            no-body
            :class="{ 'mail-read': email.isRead }"
            @click="updateEmailViewData(email)"
            class="py-1"
          >

            <b-media-aside class="media-left mr-50">
              <feather-icon
                size="20"
                icon="ShoppingBagIcon"
                class="mb-1"
              />
              <div class="user-action">
                <b-form-checkbox
                  :checked="selectedEmails.includes(email.id)"
                  @change="toggleSelectedMail(email.id)"
                  @click.native.stop
                />
              </div>
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    Заказ № {{ email.id }}
                  </h5>
                </div>
                <div class="mail-meta-item">
                  <span
                    v-for="label in email.labels"
                    :key="label"
                    class="mx-50 bullet bullet-sm"
                    :class="`bullet-${resolveLabelColor(label)}`"
                  />
                  <span class="mail-date">
                    от {{ new Date(email.dateCreate).toLocaleString() }}
                  </span>
                </div>
              </div>

              <div>
                <p class="mb-0">
                  <span v-for="item in email.items" :key="item.type" style="margin-right: 10px">
                    <feather-icon
                      :icon="item.type + 'Icon'"
                      :class="'text-' + GetColorByDate(new Date(item.files[0].dateCreate))"
                    />
                    {{ item.files.length }} - {{ new Date(item.files[0].dateCreate).toLocaleDateString() }}
                  </span>

                  <span style="margin-right: 10px">
                    <feather-icon
                      icon='CheckSquareIcon'
                      :class="'text-success'"
                    />
                    Куплено: {{ email.cntByeFiles }} шт. на {{ email.costByeFiles }}
                    <feather-icon
                      icon="WindIcon"
                      color="red"
                    />.
                  </span>

                  <span style="margin-right: 10px" v-if="email.cntFiles != email.cntByeFiles">
                    <feather-icon
                      icon='XOctagonIcon'
                      :class="email.cntFiles != email.cntByeFiles ? 'text-danger' : 'text-success'"
                    />
                    Не удалось купить файлов: {{ email.cntFiles - email.cntByeFiles  }} шт. на {{ email.cost - email.costByeFiles }}
                    <feather-icon
                      icon="WindIcon"
                      color="red"
                    />.
                  </span>
                </p>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
          :class="{'show': !emails.length}"
        >
          <h5>У Вас ещё нет ни одного заказа, для начала зайдите в Архив и добавьте файлы в корзину</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Email View/Detail -->
    <order-view
      :class="{'show': showEmailDetails}"
      :email-view-data="kadViewData"
      :opended-email-meta="opendedEmailMeta"
      :selectedKadDetail="selectedKadDetail"

      @close-email-view="showEmailDetails = false"
      @move-email-to-folder="moveOpenEmailToFolder"
      @toggle-email-starred="toggleStarred(kadViewData)"
      @update-email-label="updateOpenEmailLabel"
      @mark-email-unread="markOpenEmailAsUnread"
      @change-opened-email="changeOpenedEmail"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <order-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :emails-meta="emailsMeta"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <!-- Compose Email Modal -->
    <email-compose v-model="shallShowEmailComposeModal" />
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import OrderLeftSidebar from './OrderLeftSidebar.vue'
import OrderView from './OrderView.vue'
import ordersStoreModule from './ordersStoreModule'
import useOrders from './useOrders'
import EmailCompose from './EmailCompose.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    OrderLeftSidebar,
    OrderView,
    EmailCompose,
  },
  setup() {
    const ORDERS_APP_STORE_MODULE_NAME = 'app-orders'

    // Register module
    if (!store.hasModule(ORDERS_APP_STORE_MODULE_NAME)) store.registerModule(ORDERS_APP_STORE_MODULE_NAME, ordersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORDERS_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDERS_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const { resolveLabelColor } = useOrders()

    // Route Params
    const routeParams = computed(() => route.value.params)

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsMeta = ref({})
    const selectedKadDetail = ref(-1)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    let intervalSearch = -1
    let intervalUpdateRouteQuery = -1

    watch(routeQuery, val => {
      searchQuery.value = val
    })

    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      if (intervalSearch !== -1) {
        clearInterval(intervalSearch)
      }
      // eslint-disable-next-line no-use-before-define
      intervalSearch = setTimeout(fetchOrdersSearch, 500)
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => {
      if (intervalSearch !== -1) {
        clearInterval(intervalSearch)
      }
      // eslint-disable-next-line no-use-before-define
      intervalSearch = setTimeout(fetchOrdersSearch, 500)
    })

    const updateRouteQuery = val => {
      clearTimeout(intervalUpdateRouteQuery)
      intervalUpdateRouteQuery = setTimeout(() => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      }, 500)
    }

    const fetchOrdersSearch = () => {
      store.dispatch('app-orders/fetchOrdersSearch', {
        q: searchQuery.value,
        folder: router.currentRoute.params.folder || 'all',
        label: router.currentRoute.params.label,
      })
        .then(response => {
          // console.log('response', response)
          emails.value = response.data.Data.list
          emailsMeta.value = response.data.Data.excerptsMeta
        })
    }

    fetchOrdersSearch()

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
    }
    // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

    // ------------------------------------------------
    // Mail Actions
    // ------------------------------------------------
    const toggleStarred = email => {
      store.dispatch('app-orders/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: { isStarred: !email.isStarred },
      }).then(() => {
        // eslint-disable-next-line no-param-reassign
        email.isStarred = !email.isStarred
      })
    }

    const moveSelectedEmailsToFolder = folder => {
      store.dispatch('app-orders/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { folder },
      })
        .then(() => { fetchOrdersSearch() })
        .finally(() => { selectedEmails.value = [] })
    }

    const updateSelectedEmailsLabel = label => {
      store.dispatch('app-orders/updateEmailLabels', {
        emailIds: selectedEmails.value,
        label,
      })
        .then(() => { fetchOrdersSearch() })
        .finally(() => { selectedEmails.value = [] })
    }

    const markSelectedEmailsAsUnread = () => {
      store.dispatch('app-orders/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { isRead: false },
      })
        .then(() => { fetchOrdersSearch() })
        .finally(() => { selectedEmails.value = [] })
    }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const showEmailDetails = ref(false)
    const kadViewData = ref({})
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === kadViewData.value.id)
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
      }
    })
    const updateEmailViewData = kadInfo => {
      selectedEmails.value = []
      // Mark email is read
      store.dispatch('app-orders/fetchOrderFiles', {
        idOrder: kadInfo.id,
      })
        .then(response => {
          // If opened email is unread then decrease badge count for email meta based on email folder
          if (!kadInfo.isRead && (kadInfo.folder === 'inbox' || kadInfo.folder === 'spam')) {
            emailsMeta.value[kadInfo.folder] -= 1
          }
          kadViewData.value = kadInfo
          showEmailDetails.value = true
          // console.log('files', response.data.Data.files)
          kadViewData.value.files = response.data.Data.files

          // eslint-disable-next-line no-param-reassign
          kadInfo.isRead = true
        })
    }
    const moveOpenEmailToFolder = folder => {
      selectedEmails.value = [kadViewData.value.id]
      moveSelectedEmailsToFolder(folder)
      selectedEmails.value = []
      showEmailDetails.value = false
    }
    const updateOpenEmailLabel = label => {
      selectedEmails.value = [kadViewData.value.id]
      updateSelectedEmailsLabel(label)

      // Update label in opened email
      const labelIndex = kadViewData.value.labels.indexOf(label)
      if (labelIndex === -1) kadViewData.value.labels.push(label)
      else kadViewData.value.labels.splice(labelIndex, 1)

      selectedEmails.value = []
    }

    const markOpenEmailAsUnread = () => {
      selectedEmails.value = [kadViewData.value.id]
      markSelectedEmailsAsUnread()

      selectedEmails.value = []
      showEmailDetails.value = false
    }

    const changeOpenedEmail = dir => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === kadViewData.value.id)
      const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
      kadViewData.value = emails.value[newEmailIndex]
    }
    const GetColorByDate = date => {
      const difference = new Date().getTime() - date.getTime()
      const totalDays = Math.ceil(difference / (1000 * 3600 * 24))
      if (totalDays <= 7) return 'success'
      if (totalDays <= 30) return 'primary'
      if (totalDays <= 180) return 'warning'
      return 'danger'
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // * Watcher to reset selectedEmails
    // ? You can also use showEmailDetails (instead of `kadViewData`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([kadViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emails,
      emailsMeta,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // Mail Actions
      toggleStarred,
      moveSelectedEmailsToFolder,
      updateSelectedEmailsLabel,
      markSelectedEmailsAsUnread,

      // Email Details
      showEmailDetails,
      kadViewData,
      opendedEmailMeta,
      updateEmailViewData,
      moveOpenEmailToFolder,
      updateOpenEmailLabel,
      markOpenEmailAsUnread,
      changeOpenedEmail,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,

      GetColorByDate,

      // Kad detail
      selectedKadDetail,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
