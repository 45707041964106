<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :to="{ name: 'apps-excerpt-add' }"
            >
              Загрузить файлы
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >

            <!-- Labels -->
            <h6 class="section-label mt-1 mb-1 px-2">
              Отобразить заказы
            </h6>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :active="isDynamicRouteActive(label.route)"
                tag="a"
                @click="$emit('close-left-sidebar'); GoToRoute(label.route)"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { useRouter, isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BListGroup,
    BListGroupItem,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    emailsMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const { route } = useRouter()

    const emailFilters = [
      { title: 'Все', icon: 'FileIcon', route: { name: 'apps-order-list-folder', params: { folder: 'all' } } },
      { title: 'Об объекте', icon: 'InfoIcon', route: { name: 'apps-order-list-folder', params: { folder: 'Info' } } },
      { title: 'Переход прав', icon: 'UsersIcon', route: { name: 'apps-order-list-folder', params: { folder: 'Users' } } },
      { title: 'КПТ', icon: 'MapIcon', route: { name: 'apps-order-list-folder', params: { folder: 'Map' } } },
      { title: 'Другие', icon: 'FilePlusIcon', route: { name: 'apps-order-list-folder', params: { folder: 'FilePlus' } } },
    ]

    const emailLabel = [
      { title: 'Все', color: 'secondary', route: { name: 'apps-order-list-folder', params: { label: 'all' } } },
      { title: 'за 7 дней', color: 'success', route: { name: 'apps-order-list-folder', params: { label: '7days' } } },
      { title: 'за 1 месяц', color: 'primary', route: { name: 'apps-order-list-folder', params: { label: '30days' } } },
      { title: 'за 3 месяца', color: 'warning', route: { name: 'apps-order-list-folder', params: { label: '90days' } } },
      { title: 'за 1 год', color: 'danger', route: { name: 'apps-order-list-folder', params: { label: '1year' } } },
    ]

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    const GoToRoute = function (rout) {
      // console.log('rout', rout)

      if (rout && route.value.query && route.value.query.q) {
        // eslint-disable-next-line no-param-reassign
        rout.query = { q: route.value.query.q }
      }
      this.$router.push(rout)
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,
      GoToRoute,
      // Filter & Labels
      emailFilters,
      emailLabel,
    }
  },
}
</script>

<style>

</style>
